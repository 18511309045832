// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biografija-js": () => import("./../src/pages/biografija.js" /* webpackChunkName: "component---src-pages-biografija-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-licence-js": () => import("./../src/pages/licence.js" /* webpackChunkName: "component---src-pages-licence-js" */),
  "component---src-pages-nagrade-js": () => import("./../src/pages/nagrade.js" /* webpackChunkName: "component---src-pages-nagrade-js" */),
  "component---src-pages-objekti-js": () => import("./../src/pages/objekti.js" /* webpackChunkName: "component---src-pages-objekti-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-radovi-js": () => import("./../src/pages/radovi.js" /* webpackChunkName: "component---src-pages-radovi-js" */),
  "component---src-pages-slike-js": () => import("./../src/pages/slike.js" /* webpackChunkName: "component---src-pages-slike-js" */)
}

